.color-default {
  color: #4488ff;
}

.color-gold {
  color: #ffcc00;
}

.color-dark-gray {
  color: #333333;
}

.color-light-gray {
  color: #aaaaaa;
}
